exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-collections-garden-collection-js": () => import("./../../../src/pages/collections/garden-collection.js" /* webpackChunkName: "component---src-pages-collections-garden-collection-js" */),
  "component---src-pages-collections-wander-collection-js": () => import("./../../../src/pages/collections/wander-collection.js" /* webpackChunkName: "component---src-pages-collections-wander-collection-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-portfolio-art-js": () => import("./../../../src/pages/portfolio/art.js" /* webpackChunkName: "component---src-pages-portfolio-art-js" */),
  "component---src-pages-portfolio-graphic-design-js": () => import("./../../../src/pages/portfolio/graphic-design.js" /* webpackChunkName: "component---src-pages-portfolio-graphic-design-js" */),
  "component---src-pages-portfolio-illustration-js": () => import("./../../../src/pages/portfolio/illustration.js" /* webpackChunkName: "component---src-pages-portfolio-illustration-js" */),
  "component---src-pages-portfolio-motion-graphics-js": () => import("./../../../src/pages/portfolio/motion-graphics.js" /* webpackChunkName: "component---src-pages-portfolio-motion-graphics-js" */),
  "component---src-pages-templates-collection-item-js": () => import("./../../../src/pages/templates/collection-item.js" /* webpackChunkName: "component---src-pages-templates-collection-item-js" */)
}

